@import "./_variables";
@import "./_common";

@import "./icon-menu";
@import "./_ecommerce";
@import "./_session";
@import "./email";
@import "./pricing";
@import "./ag-grid";
@import "./responsive";
@import "./rtl-layout";

// #rangeButton5 {
// .rangebuttontextstyle:after {
//     content: 'Benutzerdefiniert';
//    }}
.MuiOutlinedInput-root{
    border-radius: 0px !important
  }
  .MuiChip-root{
		margin: 2px
	}

  /*--------------table order style --------------------*/
.table-style {
  font-family: 'Raleway', sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.table-tr{
  border:2px solid white
}
.th-style-cmd {
  text-align: left;
  color:#333333;
  background-color:white;
  font-weight: 800;
  font-size: x-small;

}
.table-tr{
border:2px solid white
}
.mrgin-table{
margin-bottom: 15px;
}
.td-style {
border: 1px solid transparent;
}
td.td-style.bg-color.td-fontsize:last-child(){
border-top-right-radius:10px;
border-bottom-right-radius:10px;
border-bottom:6px solid white

}
td.td-style.bg-color.td-fontsize:first-child(){
border-top-left-radius:10px;
border-bottom-left-radius:10px;
border-bottom:6px solid white;
padding: 0em 0em 0em 0.5em !important;

}
.bg-color{
background-color:rgba(221, 227, 232, 0.329412);
}
.bg-color-person{
background-color: #de472c !important;
color: white;
}

.bg-color-person a{
  color:white !important

}

